import React, { ReactElement } from 'react';
import { formatPriceNoRound } from '../utils/data.utils';
import moment from 'moment';

type CancellationPolicyProps = {
  cancellationObject: { [index: string]: any };
  selectedRooms?: { [index: string]: any };
  totalFare?: number;
};
const CancellationPolicyHooks = () => {
  const cancellationPolicy2 = ({ cancellationObject, selectedRooms }: CancellationPolicyProps) => {
    // console.log(); return;
    let totalRoomPrice: number = 0;
    // eslint-disable-next-line
    selectedRooms?.map((value: { [index: string]: any }, key: React.Key) => {
      totalRoomPrice += value.TotalFare;
    });
    let cancellations: ReactElement[] = [];
    // eslint-disable-next-line
    for (let i = 0; i < Object.keys(cancellationObject).length; i++) {
      const objKey: string = Object.keys(cancellationObject)[i];
      //   cancellations.push(
      //     // <div className="mb-1 pt-3">
      //       {/* <h6>{objKey}</h6> */}
      //     // </div>
      //   );


      // eslint-disable-next-line
      [cancellationObject[objKey]]?.map((policy: { [index: string]: any }, key: number) => {
        // reverse the date
        const dateArray = policy.FromDate.split(' ')[0].split('-').reverse();
        // get the partial amount
        let partialAmount: string = `PHP ${formatPriceNoRound(policy.CancellationCharge)}`;
        // if the charge type is percentage get the percentage amount
        if (policy.ChargeType === 'Percentage') {
          const percent = (policy.CancellationCharge / totalRoomPrice) * 100;
          partialAmount = `${formatPriceNoRound(percent)}% `;
        }

        // get the next date
        let nextDateArray: string[] | undefined = [];
        // eslint-disable-next-line
        [cancellationObject[objKey][key + 1]].map((policy: { [index: string]: any }, _key: number) => {
          nextDateArray = policy?.FromDate.split(' ')[0].split('-').reverse();
        });
        // get the last date
        let lastDateArray: string[] | undefined = [];
        // eslint-disable-next-line
        [cancellationObject[objKey][cancellationObject[objKey].length - 1]].map((policy: { [index: string]: any }, _key: number) => {
          lastDateArray = policy?.FromDate.split(' ')[0].split('-').reverse();
        });
        // format the dates
        const date = moment(`${dateArray[0]}-${dateArray[1]}-${dateArray[2]}`).format('MMMM DD, YYYY');
        const nextDate = nextDateArray !== undefined ? moment(`${nextDateArray[0]}-${nextDateArray[1]}-${Number(nextDateArray[2]) - 1}`).format('MMMM DD, YYYY') : undefined;
        const lastDate = lastDateArray !== undefined ? moment(`${lastDateArray[0]}-${lastDateArray[1]}-${lastDateArray[2]}`).format('MMMM DD, YYYY') : undefined;

        /**
         * Fully Refundable policy
         * if the next date not undefined return UNTIL NEXT DATE (11:59pm)
         * else if last date is undefined return THIS ROOM IS 100% REFUNDABLE
         * else return null
         *
         * Partially Refundable policy
         * if last date is undefined and date is undefined return PARTIAL AMOUNT IS REFUNDABLE WHEN CANCELLED
         * else if last date is undefined and date is not undefined return PARTIAL AMOUNT IS REFUNDABLE STARTING DATE
         * else if last date is not undefined and date is not undefined return PARTIAL AMOUNT IS REFUNDABLE UNTIL DATE
         *
         * Non refundable policy
         * if the date is in the past then return FREE CANCELLATION IS NOT AVAILABLE FOR THIS ROOM
         * else return STARTING DATE (12:00am)
         */

        if (policy.CancellationCharge === 0) {
          cancellations.push(
            <li className="text-success" key={key}>
              <span className=" text-success font-semibold ">Fully Refundable</span>
              <span className="d-block text-success  ps-3 pt-1 ">
                {nextDate !== undefined ? `Until ${nextDate} (11:59pm)` : null}
                {lastDate === undefined ? `This room is 100% refundable` : null}
              </span>
            </li>
          );
        } else if (policy.CancellationCharge !== 100 && policy.CancellationCharge !== 0) {
          cancellations.push(
            <li className="mb-1 text-warning" key={key}>
              <span className=" text-warning font-semibold ">Partially Refundable</span>
              <span className="d-block text-warning  ps-3 pt-1">
                {lastDate === undefined && date === undefined ? `${partialAmount} is refundable when cancelled` : null}
                {lastDate === date ? `${partialAmount} is refundable starting ${date}` : null}
                {lastDate !== undefined && lastDate !== date ? `${partialAmount} is refundable until ${moment(lastDate).subtract(1, 'day').format('MMMM DD, YYYY')}` : null}
              </span>
            </li>
          );
        } else if (policy.CancellationCharge === 100) {
          cancellations.push(
            <li className="mb-1 text-danger" key={key}>
              <span className=" text-danger font-semibold">Non-Refundable</span>
              <span className="d-block text-danger ps-3 pt-1">{moment() > moment(date) ? `Free cancellation is not available for this room` : `Starting ${date} (12:00am)`}</span>
            </li>
          );
        }
      });

      return cancellations;
    } // end of for loop
  };

  const cancellationPolicy = (cancellationObject: { [index: string]: any }, totalFare: number = 0) => {
    let cancellations: ReactElement[] = [];

    // return cancellationObject;
    for (let i = 0; i < Object.keys(cancellationObject).length; i++) {
      const objKey: string = Object.keys(cancellationObject)[i];
      cancellations.push(
        <div className="mb-1 pt-3">
          <h6>{objKey}</h6>
        </div>
      );
      // console.log(objKey);
      // eslint-disable-next-line
      cancellationObject[objKey].map((policy: { [index: string]: any }, key: number) => {
        // reverse the date
        const dateArray = policy.FromDate.split(' ')[0].split('-').reverse();
        // get the partial amount
        let partialAmount: string = `PHP ${formatPriceNoRound(policy.CancellationCharge)}`;
        // if the charge type is percentage get the percentage amount
        if (policy.ChargeType === 'Percentage') {
          const percent = (policy.CancellationCharge / totalFare) * 100;
          partialAmount = `${formatPriceNoRound(percent)}% `;
        }

        // get the next date
        let nextDateArray: string[] | undefined = [];
        // eslint-disable-next-line
        [cancellationObject[objKey][key + 1]].map((policy: { [index: string]: any }, _key: number) => {
          nextDateArray = policy?.FromDate.split(' ')[0].split('-').reverse();
        });
        // get the last date
        let lastDateArray: string[] | undefined = [];
        // eslint-disable-next-line
        [cancellationObject[objKey][cancellationObject[objKey].length - 1]].map((policy: { [index: string]: any }, _key: number) => {
          lastDateArray = policy?.FromDate.split(' ')[0].split('-').reverse();
        });
        // format the dates
        const date = moment(`${dateArray[0]}-${dateArray[1]}-${dateArray[2]}`).format('MMMM DD, YYYY');
        const nextDate = nextDateArray !== undefined ? moment(`${nextDateArray[0]}-${nextDateArray[1]}-${Number(nextDateArray[2]) - 1}`).format('MMMM DD, YYYY') : undefined;
        const lastDate = lastDateArray !== undefined ? moment(`${lastDateArray[0]}-${lastDateArray[1]}-${lastDateArray[2]}`).format('MMMM DD, YYYY') : undefined;

        /**
         * Fully Refundable policy
         * if the next date not undefined return UNTIL NEXT DATE (11:59pm)
         * else if last date is undefined return THIS ROOM IS 100% REFUNDABLE
         * else return null
         *
         * Partially Refundable policy
         * if last date is undefined and date is undefined return PARTIAL AMOUNT IS REFUNDABLE WHEN CANCELLED
         * else if last date is undefined and date is not undefined return PARTIAL AMOUNT IS REFUNDABLE STARTING DATE
         * else if last date is not undefined and date is not undefined return PARTIAL AMOUNT IS REFUNDABLE UNTIL DATE
         *
         * Non refundable policy
         * if the date is in the past then return FREE CANCELLATION IS NOT AVAILABLE FOR THIS ROOM
         * else return STARTING DATE (12:00am)
         */

        if (policy.CancellationCharge === 0) {
          cancellations.push(
            <div className="" key={key}>
              <span className="d-block text-success font-medium">Fully Refundable</span>
              <span>
                {nextDate !== undefined ? `Until ${nextDate} (11:59pm)` : null}
                {lastDate === undefined ? `This room is 100% refundable` : null}
              </span>
            </div>
          );
        } else if (policy.CancellationCharge !== 100 && policy.CancellationCharge !== 0) {
          cancellations.push(
            <div className="mb-1" key={key}>
              <span className="d-block text-warning font-medium">Partially Refundable</span>
              <span className="d-block">
                {lastDate === undefined && date === undefined ? `${partialAmount} is refundable when cancelled` : null}
                {lastDate === date ? `${partialAmount} is refundable starting ${date}` : null}
                {lastDate !== undefined && lastDate !== date ? `${partialAmount} is refundable until ${moment(lastDate).subtract(1, 'day').format('MMMM DD, YYYY')}` : null}
              </span>
            </div>
          );
        } else if (policy.CancellationCharge === 100) {
          cancellations.push(
            <div className="mb-1" key={key}>
              <span className="d-block text-danger font-medium">Non-Refundable</span>
              <span className="d-block">{moment() > moment(date) ? `Free cancellation is not available for this room` : `Starting ${date} (12:00am)`}</span>
            </div>
          );
        }
      });
    } // end of for loop

    return cancellations;
  };

  return { cancellationPolicy2, cancellationPolicy };
};

export default CancellationPolicyHooks;

import React, { useEffect, useState } from 'react';
import { FloatingLabelFormControl } from '../FormControl';
import { RadioGroup, RadioGroupItem } from '../ui/radiogroup';
import { Label } from '../ui/label';
import { HotelGuestStore } from '../../store/HotelGuestsStore';
import { checkEmail } from '../../utils/data.utils';
import { InfoCircle } from '../../resources/svg/InfoSvg';
import { CountryCodesSelectInput } from '../CountryCodesSelectInput';

const HotelFormInformation = ({ setContinueBtn }: { setContinueBtn: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const { guest, setGuest, titleError, setTitleError, firstNameError, setFirstNameError, lastNameError, setLastNameError, emailError, setEmailError, mobileError, setMobileError } = HotelGuestStore();
  const [countryCode, setSetCountryCode] = useState('+63');
  // eslint-disable-next-line
  const [bookingForSomeone, setBookingForSomeone] = useState(false);

  useEffect(() => {
    setGuest({ ...{ code: countryCode } });
    // eslint-disable-next-line
  }, [countryCode]);

  useEffect(() => {
    if (localStorage.userDetails) {
      const loggedUserDetails = JSON.parse(localStorage.userDetails);
      setGuest({
        ...{
          title: loggedUserDetails.title,
          firstName: loggedUserDetails.firstName,
          middleName: loggedUserDetails.middleName,
          lastName: loggedUserDetails.lastName,
          email: loggedUserDetails.email,
          mobile: loggedUserDetails.mobileNumber,
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (guest.title && guest.firstName && guest.lastName && guest.email) {
      setContinueBtn(true);
    } else {
      setContinueBtn(false);
    }
  }, [guest.title, guest.firstName, guest.lastName, guest.email]);

  return (
    <div className="font-footnote border-bottom mb-5 flex flex-col items-start justify-start gap-[3.75rem] pb-5 text-left text-[0.81rem] text-black">
      <div className="flex w-full flex-col items-start justify-start gap-[2rem]">
        <div className="font-heading-2 w-full text-[2.13rem]">
          <div className="flex flex-col items-start justify-start gap-[1rem]">
            <b className="leading-[2.56rem] text-text-colors-text-primary">Guest Information</b>
            <div className="font-footnote text-text-colors-text-primary flex items-center text-[1rem] leading-[1.5rem] font-medium">Please confirm these details before we proceed to payment!</div>
          </div>
        </div>

        <RadioGroup defaultValue="option-one" className="flex items-center justify-center gap-5">
          <div className="flex flex-row items-center justify-start gap-[0.5rem]">
            <RadioGroupItem onClick={() => setBookingForSomeone(false)} value="option-one" id="option-one" />
            <Label htmlFor="option-one" className="font-footnote relative flex shrink-0 cursor-pointer items-center text-sm text-text-colors-text-primary font-medium">
              I'm the Guest
            </Label>
          </div>

          <div className="flex flex-row items-center justify-start gap-[0.5rem]">
            <RadioGroupItem onClick={() => setBookingForSomeone(true)} value="option-two" id="option-two" />
            <Label htmlFor="option-two" className="font-footnote relative flex shrink-0 cursor-pointer items-center text-sm text-text-colors-text-primary font-medium">
              I'm booking for someone else
            </Label>
          </div>
        </RadioGroup>

        {/* form */}
        <div className="text-text-colors-text-secondary w-full">
          <form className="">
            <div className="flex w-full flex-col items-start justify-start gap-[0.75rem]">
              <div className="w-full flex gap-3">
                <div className="w-25">
                  <FloatingLabelFormControl
                    type={'select'}
                    style={{ border: guest.title ? '2px solid #4FA2B4' : '2px solid #D9d9d9d9' }}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      const title = e.target.value;
                      if (title.length === 0) {
                        setTitleError(true);
                        return;
                      }
                      setTitleError(false);
                      setGuest({ ...{ title: title } });
                    }}
                    selectOptions={
                      <React.Fragment>
                        <option value="" disabled selected></option>
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Ms">Ms</option>
                      </React.Fragment>
                    }
                    label="Title"
                    placeholder="Title"
                    id="title"
                    success={guest.title ? true : false}
                    error={titleError}
                  />
                  {titleError ? (
                    <span className="align-items-center mt-2 flex" style={{ color: '#BD0A00' }}>
                      <InfoCircle _color="#BD0A00" />
                      &nbsp;Choose title.
                    </span>
                  ) : null}
                </div>
                <div className="w-75">
                  <FloatingLabelFormControl
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length === 0) {
                        setFirstNameError(true);
                      } else {
                        setFirstNameError(false);
                      }
                      setGuest({ ...{ firstName: e.target.value } });
                    }}
                    success={guest.firstName ? true : false}
                    error={firstNameError}
                    defaultValue={guest.firstName}
                    id="first-name"
                    maxLength={20}
                    type={'text'}
                    placeholder={'First Name*'}
                    label={'First Name*'}
                    style={{ border: guest.firstName ? '2px solid #4FA2B4' : '2px solid #d9d9d9' }}
                  />
                  {firstNameError ? (
                    <span className="align-items-center mt-2 flex" style={{ color: '#BD0A00' }}>
                      <InfoCircle _color="#BD0A00" />
                      &nbsp;Must contain your last name.
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="w-full">
                <FloatingLabelFormControl
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setGuest({ ...{ middleName: e.target.value } });
                  }}
                  success={guest.middleName ? true : false}
                  defaultValue={guest.middleName}
                  id="middle-name"
                  maxLength={12}
                  type={'text'}
                  placeholder={'Middle Name (Optional)'}
                  label={'Middle Name (Optional)'}
                  style={{ border: guest.middleName ? '2px solid #4FA2B4' : '2px solid #d9d9d9' }}
                />
              </div>

              <div className="w-full">
                <FloatingLabelFormControl
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value.length === 0) {
                      setLastNameError(true);
                    } else {
                      setLastNameError(false);
                    }
                    setGuest({ ...{ lastName: e.target.value } });
                  }}
                  success={guest.lastName ? true : false}
                  error={lastNameError}
                  defaultValue={guest.lastName}
                  id="last-name"
                  maxLength={12}
                  type={'text'}
                  placeholder={'Last Name*'}
                  label={'Last Name*'}
                  style={{ border: guest.lastName ? '2px solid #4FA2B4' : '2px solid #d9d9d9' }}
                />
                {lastNameError ? (
                  <span className="align-items-center mt-2 flex" style={{ color: '#BD0A00' }}>
                    <InfoCircle _color="#BD0A00" />
                    &nbsp;Must contain your last name.
                  </span>
                ) : null}
              </div>
            </div>

            <span className="font-footnote mt-2 inline-block font-normal text-sm">Make sure it matches the name on your passport or any valid government IDs.</span>

            <div className="mt-3 flex w-full flex-col items-start justify-start gap-2">
              <div className="w-full">
                <FloatingLabelFormControl
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const validateEmail = checkEmail(e.target.value);
                    setEmailError(!validateEmail);
                    setGuest({ ...{ email: e.target.value } });
                  }}
                  success={guest.email ? true : false}
                  error={emailError}
                  defaultValue={guest.email}
                  id="email-address"
                  type={'email'}
                  label="Email Address*"
                  placeholder="Email Address*"
                  style={{ border: guest.email ? '2px solid #4FA2B4' : '2px solid #d9d9d9' }}
                />
                {emailError ? (
                  <span className="align-items-center mt-2 flex" style={{ color: '#BD0A00' }}>
                    <InfoCircle _color="#BD0A00" />
                    &nbsp;Email address is invalid format.
                  </span>
                ) : null}
              </div>

              <div className="flex w-full gap-2">
                <div className="w-25">
                  <CountryCodesSelectInput
                    countryCode={countryCode}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSetCountryCode(e.target.value)}
                    style={{ border: countryCode ? '2px solid #4FA2B4' : '2px solid #d9d9d9' }}
                  />
                </div>
                <div className="w-75">
                  <FloatingLabelFormControl
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length < 6) {
                        setMobileError(true);
                      } else {
                        setMobileError(false);
                      }
                      setGuest({ ...{ mobile: e.target.value } });
                    }}
                    onKeyDown={(e) => {
                      if (!Number.isNaN(Number(e.key)) || e.key === 'Backspace') {
                      } else {
                        e.preventDefault();
                      }
                    }}
                    pattern="[0-9]*"
                    defaultValue={guest.mobile}
                    success={guest.mobile ? true : false}
                    error={mobileError}
                    label="Mobile Number"
                    placeholder="Mobile Number"
                    type="text"
                    maxLength={10}
                    style={{ border: guest.mobile ? '2px solid #4FA2B4' : '2px solid #d9d9d9' }}
                  />
                  {mobileError ? (
                    <span className="align-items-center mt-2 flex" style={{ color: '#BD0A00' }}>
                      <InfoCircle />
                      &nbsp;Invalid Mobile Number.
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HotelFormInformation;

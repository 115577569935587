/**
 * list of api routes from the backend server
 */

// const staging_api = 'http://13.115.0.30:3000/api';
export const X_API_KEY = 'CrULDGCEMqfDY6JMQ4zMTrtATA1aAywe';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL as string;

const server = {
  apiUrl: API_BASE_URL,
  apiUtil: API_BASE_URL,
};

// authentications
export const signUp = (): string => `${server.apiUrl}/auth/sign-up`;
export const logIn = (): string => `${server.apiUrl}/auth/sign-in`;
export const logInGuest = (): string => `${server.apiUrl}/auth/sign-up-guest`;
export const signOutApiRoute = (): string => `${server.apiUrl}/auth/sign-out`;
export const verifyEmail = (): string => `${server.apiUrl}/auth/verify-email`;
export const resendEmail = (): string => `${server.apiUrl}/auth/refresh-verification-email`;
export const verifyEmailToken = (token: string): string => `${server.apiUrl}/auth/validate-token?authCode=${token}`;
export const refreshTokenUrl = (): string => `${server.apiUrl}/auth/refresh-token`;
export const getUserDetails = (): string => `${server.apiUrl}/users/get-details`;
export const deleteUserRoute = (): string => `${server.apiUrl}/users/delete`;
export const uploadProfilePhotoUrl = (): string => `${server.apiUrl}/users/local/file`;
export const uploadProfilePhotoUrlOld = (): string => `${server.apiUrl}/users/local/file-old`;
export const facebookLogin = (): string => `${server.apiUrl}/auth/facebook`;
export const facebookLoginRedirect = (): string => `${facebookLogin()}/redirect`;

// change password
export const validateOldPasswordRoute = (): string => `${server.apiUrl}/users/local/password/verify`;
export const changeOldPasswordRoute = (): string => `${server.apiUrl}/users/local/password/change`;

// traveler details
export const saveCompanionDetails = (): string => `${server.apiUrl}/flights/traveler-details/user`;
export const getCompanions = (): string => `${server.apiUrl}/flights/traveler-details/user`;

// flights
export const searchAirport = (item: string): string => `${server.apiUtil}/galago-utilities/airport-lists?keyword=${item}&pagination=50`;
export const searchOneWayFlightsApiRoute = (): string => `${server.apiUrl}/flights/one-way/search`;
export const searchRoundTripFlightsApiRoute = (): string => `${server.apiUrl}/flights/roundtrip/search2`; // mainly used in roundtrip
export const searchRoundTripBundledFlightsApiRoute = (): string => `${server.apiUrl}/flights/roundtrip/search2/bundled`; // mainly used in roundtrip bundled
export const createBookingFlightTransactionApiRoute = (): string => `${server.apiUrl}/transactions/flights`;

export const preBookFlightApiRoute = (flightType: 'one-way' | 'roundtrip'): string => `${server.apiUrl}/flights/${flightType}/pre-book`; // mystifly pre-booking
export const preBookFlightV2ApiRoute = (flightType: 'one-way' | 'roundtrip'): string => `${server.apiUrl}/flights/${flightType}/pre-book2`; // mystifly pre-booking

export const preBookGalagoApiRoute = (flightType: 'one-way' | 'roundtrip'): string => `${server.apiUrl}/prebookings/flights/${flightType}`; // galago pre-booking
export const preBookGalagoV2ApiRoute = (flightType: 'one-way' | 'roundtrip'): string => `${server.apiUrl}/prebookings/flights/v2/${flightType}`; // galago pre-booking

export const bookingFlightsApiRoute = (flightType: 'one-way' | 'roundtrip'): string => `${server.apiUrl}/bookings/flights/${flightType}`; // galago booking
export const bookingFlightsV2ApiRoute = (flightType: 'one-way' | 'roundtrip'): string => `${server.apiUrl}/bookings/flights/v2/${flightType}`; // galago booking

export const bookingFlightsReference = (referenceId: string): string => `${server.apiUrl}/payments/flights/reference/${referenceId}`;
export const getBookingFlightsDetails = (id: string): string => `${server.apiUrl}/providers/mystifly/flight-details/payment-reference/${id}`;

// hotels
export const hotelLocation = (location: string): string => `${server.apiUtil}/galago-utilities/hotel-lists?keyword=${location}`;
export const hotelSearch = (): string => `${server.apiUrl}/hotels/search`;
export const createBookingHotelTransactionApiRoute = (): string => `${server.apiUrl}/transactions/hotels`; // hotel transaction
export const preBookingHotelTBOApiRoute = (): string => `${server.apiUrl}/hotels/pre-book`; // tbo pre-booking
export const preBookingHotelGalagoApiRoute = (): string => `${server.apiUrl}/prebookings/hotels`; // galago hotel pre-booking
export const bookingHotelGalagoApiRoute = (): string => `${server.apiUrl}/bookings/hotels`; // galago hotel booking
export const bookingHotelConfirmationApiRoute = (id: string): string => `${server.apiUrl}/providers/tbo/hotel-details/payment-reference/${id}`;
export const roomsPrebookingApiRoute = (): string => `${server.apiUrl}/hotels/hotelRoomDetails`; 
// third party api
// export const countryPhoneCodesApiRoute = (): string => `https://restcountries.com/v3.1/all?fields=name,capital,flag,idd,cca2,flags`;
export const countryPhoneCodesApiRoute = (): string => `${server.apiUrl}/galago-utilities/country-mobile-code-lists`;
// export const nationalityApiRoute = (): string => `https://restcountries.com/v3.1/all?fields=name,demonyms,cca2`;
export const nationalityApiRoute = (): string => `${server.apiUrl}/galago-utilities/nationality-lists`;
export const airlineFlyerApiRoute = (MarketingCarriercode: string): string => `${server.apiUtil}/galago-utilities/ffp-airline-lists/${MarketingCarriercode}`;
export const ASSETS_API = () => {
  return {
    public: {
      gif: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/galago-v1-web/galago-v1-web/public/gif',
      images: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/galago-v1-web/galago-v1-web/public/images',
    },
    resources: {
      fonts: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/galago-v1-web/galago-v1-web/src/resources/fonts',
      images: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/galago-v1-web/galago-v1-web/src/resources/images',
      blogs: 'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers',
    },
  };
};

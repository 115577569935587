import React from 'react';
import { NavBar } from '../../components/NavBar';
import Footer from '../../components/Footer';

const BookingHistory = () => {
  return (
    <>
      <NavBar />
      <main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Bookings</h1>

              <div className="text-sm font-medium text-center text-gray-500 border-gray-200 dark:text-gray-400 dark:border-gray-700 mb-5">
                <ul className="flex flex-wrap -mb-px bg-[#F9FFFF]">
                  <li className="me-2">
                    <a href="#/history" onClick={(e) => e.preventDefault()} className="inline-block p-4 text-[#016E7F] text-lg">
                      Flight
                    </a>
                  </li>
                  <li className="me-2">
                    <a href="#/history" onClick={(e) => e.preventDefault()} className="inline-block p-4 text-[#016E7F] text-lg" aria-current="page">
                      Hotel
                    </a>
                  </li>
                </ul>
              </div>

              <div className="tab-content mb-10">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, laudantium alias? Architecto rerum laudantium, illo pariatur aliquam, cumque officiis, eos eveniet odit ab ipsam. Ut
                ipsam nemo dicta vero ullam dignissimos, similique alias aliquam provident cumque aperiam accusantium tempore iure nam hic voluptates velit tenetur, quod optio perferendis maxime non
                porro vel. Illum, molestiae dolores ipsa totam odit impedit blanditiis expedita velit, cupiditate vero suscipit quo dolorum aliquam dignissimos, id doloremque in culpa fugiat? Qui vel
                id sit nesciunt a dolorem voluptate. A fugit temporibus animi ea eum doloribus perferendis, eos aliquid, explicabo sunt ullam cupiditate sapiente sit unde officiis?
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default BookingHistory;

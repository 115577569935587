import React from 'react';
import HotelConfirmationPage from './pages/book/hotels/HotelConfirmationPage';
import HotelPaymentPage from './components/Hotels/HotelPaymentPage';
import HotelProfile from './pages/book/hotels/HotelProfile';
import HotelSearchResultsPage from './pages/search/hotels/HotelsSearchResultsPage';
import { Logout } from './components/Logout';
import BlogMainPage from './pages/blog/BlogMainPage';
import BookingSummaryPage from './pages/book/flights/BookingSummaryPage';
import FlightIndex from './pages/book/flights/FlightIndex';
import { NotFound } from './pages/error/NotFound';
import { Index } from './pages/Index';
import UserProfile from './pages/profile/UserProfile';
import { FlightResults } from './pages/search/flights/FlightResults';
import { createHashRouter } from 'react-router-dom';
import { SomethingWentWrong } from './pages/error/SomethingWentWrong';
import GuideToEthical from './pages/blog/articles/GuideToEthical';
import EthicalCrashCourse from './pages/blog/articles/EthicalCrashCourse';
import GadgetsAndTravel from './pages/blog/articles/GadgetsAndTravel';
import PhilippineRoadTrip from './pages/blog/articles/PhilippineRoadTrip';
import BeatTheHeat from './pages/blog/articles/BeatTheHeat';
import IslandEscape from './pages/blog/articles/IslandEscape';
import HauntedPlaces from './pages/blog/articles/HauntedPlaces';
import TipsForASpooky from './pages/blog/articles/TipsForASpooky';
import TypesOfTraveler from './pages/blog/articles/TypesOfTraveler';
import CebuTours from './pages/blog/articles/CebuTours';
import ThingsToDoInElNido from './pages/blog/articles/ThingsToDoInElNido';
import BoholTours from './pages/blog/articles/ThingsToDoInBohol';
import UltimateGuideToElNido from './pages/blog/articles/UltimateGuideToElNido';
import FirstTimeFly from './pages/blog/articles/FlyingFirstTimeTips';
import BoholHotelsAndResorts from './pages/blog/articles/BoholHotelsAndResorts';
import IslandHoppingEssentials from './pages/blog/articles/IslandHoppingEssentials';
import SurviveNAIAImmigration from './pages/blog/articles/SurviveNAIAImmigration';
import GuideToTravelRequirements from './pages/blog/articles/GuideToTravelRequirements';
import BoracayHotelGuide from './pages/blog/articles/BoracayHotelGuide';
import CebuHotels from './pages/blog/articles/CebuHotels';
import NavigatingRainySeason from './pages/blog/articles/NavigatingRainySeason';
import TheUltimateGuide from './pages/blog/articles/TheUltimateGuide';
import { ConfirmationPage } from './pages/book/confirmation/ConfirmationPage';
import BoholTour from './pages/blog/articles/BoholTour';
import MobilePhotographyTips from './pages/blog/articles/MobileTravelPhotographyTips';
import MysticalPlacesPhilippines from './pages/blog/articles/MysticalPlacesPhilippines';
import OffPeakAdventures from './pages/blog/articles/OffPeakAdventures';
import RemoteIslandsInPhilippines from './pages/blog/articles/RemoteIslandsInPhilippines';
import BaguioStaycationGuide from './pages/blog/articles/BaguioStaycationGuide';
import ThingsToDoInTagaytay from './pages/blog/articles/ThingsToDoInTagaytay';
import HiddenGemsOfBaguio from './pages/blog/articles/HiddenGemsOfBaguio';
import TermsAndCondition from './pages/tnc/TermsAndCondition';
import PrivacyAndPolicy from './pages/privacypolicy/PrivacyAndPolicy';
import Register from './pages/registration/Register';
import { ExpiredEmailLink } from './pages/error/ExpiredEmailLink';
import { ExistingAccount } from './pages/success/ExistingAccount';
import { ExpiredEmailLinkWithExistingUser } from './pages/error/ExpiredEmailLinkWithExistingUser';
import BookingHistory from './pages/history/BookingHistory';
// import HelpCenter from './pages/faqs/HelpCenter';

// see full documentation: https://reactrouter.com/en/main/routers/create-browser-router
const router = createHashRouter([
  //*INDEX PAGE
  { path: '/', element: <Index />, errorElement: <NotFound /> },
  { path: 'terms-and-conditions', element: <TermsAndCondition /> },
  { path: 'privacy-policy', element: <PrivacyAndPolicy /> },
  { path: 'profile', element: <UserProfile /> },
  { path: 'logout', element: <Logout /> },
  { path: 'register', element: <Register /> },
  { path: 'history', element: <BookingHistory /> },
  { path: 'expired-email-link', element: <ExpiredEmailLink /> },
  { path: 'expired-email-link-account', element: <ExpiredEmailLinkWithExistingUser /> },
  { path: 'account-exist', element: <ExistingAccount /> },
  // { path: 'help-center', element: <HelpCenter /> },

  //*FLIGHTS ROUTING
  { path: 'book/flight/', element: <FlightIndex /> },
  { path: 'book/summary', element: <BookingSummaryPage /> },

  //*HOTEL ROUTING
  { path: 'hotel/results', element: <HotelSearchResultsPage /> },
  { path: 'hotel/profile', element: <HotelProfile /> },
  { path: 'hotel/guest/form', element: <HotelConfirmationPage /> },
  { path: 'hotel/payment/page', element: <HotelPaymentPage /> },
  { path: 'payment-confirmation', element: <ConfirmationPage /> },

  { path: 'flights/:flightType', element: <FlightResults /> },

  //* BLOGS ROUTING
  { path: 'blogs', element: <BlogMainPage /> },
  { path: 'tips-for-a-spooky-but-safe-ghost-hunting-adventure', element: <TipsForASpooky /> },
  { path: 'there-are-3-types-of-travelers-which-one-are-you?', element: <TypesOfTraveler /> },
  { path: 'what-to-do-in-cebu-top-10-best-cebu-tours', element: <CebuTours /> },
  { path: 'things-to-do-in-el-nido-for-an-adventure-filled-trip', element: <ThingsToDoInElNido /> },
  { path: 'galago-guide-things-to-do-in-bohol', element: <BoholTours /> },
  { path: 'your-ultimate-guide-to-el-nido-palawan', element: <UltimateGuideToElNido /> },
  { path: 'flying-for-the-first-time-here-are-some-helpful-tips-for-you', element: <FirstTimeFly /> },
  { path: 'where-to-stay-in-bohol-best-hotels-and-resorts', element: <BoholHotelsAndResorts /> },
  { path: 'discover-the-best-things-to-do-in-bohol-all-in-one-tour', element: <BoholTour /> },
  { path: 'whats-in-our-beach-bags-island-hopping-essentials', element: <IslandHoppingEssentials /> },
  { path: 'how-to-survive-naia-immigration', element: <SurviveNAIAImmigration /> },
  { path: 'guide-to-travel-requirements-in-the-philippines', element: <GuideToTravelRequirements /> },
  { path: 'boracay-hotel-guide-where-to-stay', element: <BoracayHotelGuide /> },
  { path: 'top-hotels-to-stay-at-in-Cebu', element: <CebuHotels /> },
  { path: 'a-guide-to-ethical-and-sustainable-tourism-in-the-philippines', element: <GuideToEthical />, errorElement: <NotFound /> },
  { path: 'travelers-crash-course-on-filipino-customs-traditions,-and-cultural-etiquette', element: <EthicalCrashCourse />, errorElement: <NotFound /> },
  { path: 'gadgets-and-travel-apps-every-traveler-should-have', element: <GadgetsAndTravel />, errorElement: <NotFound /> },
  { path: 'philippines-road-trip-guide-destinations-you-can-reach-by-car', element: <PhilippineRoadTrip />, errorElement: <NotFound /> },
  { path: 'beat-the-heat-explore-the-coldest-places-in-the-philippines', element: <BeatTheHeat />, errorElement: <NotFound /> },
  { path: 'the-ultimate-guide-to-traveling-with-food-allergies', element: <TheUltimateGuide />, errorElement: <NotFound /> },
  { path: 'need-an-island-escape-check-into-these-hotels-in-el-nido', element: <IslandEscape />, errorElement: <NotFound /> },
  { path: 'add-these-11-haunted-places-in-the-philippines-to-your-ghost-tour-bucket-list', element: <HauntedPlaces />, errorElement: <NotFound /> },
  { path: 'mobile-travel-photography-tips', element: <MobilePhotographyTips />, errorElement: <NotFound /> },
  { path: 'navigating-the-rainy-season-tips-for-traveling-in-the-philippines', element: <NavigatingRainySeason />, errorElement: <NotFound /> },
  { path: 'beyond-the-veil-5-mystical-places-to-visit-in-the-philippines', element: <MysticalPlacesPhilippines />, errorElement: <NotFound /> },
  { path: 'off-peak-october-adventures-philippine-destinations-you-can-enjoy-while-avoiding-crowds', element: <OffPeakAdventures />, errorElement: <NotFound /> },
  { path: 'unplug-and-unwind-5-remote-islands-in-the-philippines-for-a-digital-detox', element: <RemoteIslandsInPhilippines />, errorElement: <NotFound /> },
  { path: 'baguio-staycation-guide-the-best-hotels-in-baguio', element: <BaguioStaycationGuide />, errorElement: <NotFound /> },
  { path: 'things-to-do-on-a-tagaytay-day-trip', element: <ThingsToDoInTagaytay />, errorElement: <NotFound /> },
  { path: 'baguio-off-the-beaten-path-unique-places-to-visit-in-baguio', element: <HiddenGemsOfBaguio />, errorElement: <NotFound /> },

  //* error route page
  { path: 'something-went-wrong', element: <SomethingWentWrong /> },
]);
export { router };

import React from 'react';
import { GalagoButton } from '../../../components/Button';
import { PayMongoHook } from '../../../hooks/PayMongoHook';
import { HotelBookingHooks } from '../../../hooks/HotelBookingHooks';
import moment from 'moment';
import { HTTP_CREATED, HTTP_OK } from '../../../utils/httpResponse.utils';
import { GallyWinkSvg } from '../../../resources/svg/GallySvg';

type HotelBookingModalProps = { onClose?: () => void; urlPayload: { [index: string]: any }; hotel: { [index: string]: any } };

const HotelBookingModal = ({ onClose, urlPayload, hotel }: HotelBookingModalProps) => {
  // const navigate = useNavigate();
  const { selectedRooms, guest, searchCriteria, transactionId } = urlPayload;
  const { pay } = PayMongoHook();
  const { bookHotel } = HotelBookingHooks();

  // eslint-disable-next-line
  const [payNowClicked, setPayNowClicked] = React.useState(false);
  const [disablePayButton, setDisablePayButton] = React.useState(false);
  const [disableCancelPayButton, setDisableCancelPayButton] = React.useState(false);

  // remove the Rooms key from the hotel object
  delete hotel.Rooms;

  const onClickPayNow = async () => {
    setPayNowClicked(true);
    setDisableCancelPayButton(true);
    setDisablePayButton(true);

    let totalRoomPrice = 0;
    // eslint-disable-next-line
    selectedRooms.map((value: { [index: string]: any }, key: React.Key) => {
      totalRoomPrice += value.TotalFare;
    });

    // totalRoomPrice = Number(totalRoomPrice.toFixed(2)) * 100;
    totalRoomPrice = Number((Number(totalRoomPrice.toFixed(2)) * 100).toFixed(2));

    const payResponse = await pay({
      setPayNowClicked: setPayNowClicked,
      description: transactionId,
      line_items: [
        {
          amount: totalRoomPrice,
          currency: 'PHP',
          description: transactionId,
          images: null,
          name: 'Hotel Ticket',
          quantity: 1,
        },
      ],
      product: 'hotels',
      platform: 'web',
      referenceNumber: transactionId,
      stage: window.location.origin,
    });

    const { checkout_url, payment_intent, reference_number } = payResponse as { checkout_url: string; payment_intent: { [index: string]: any }; reference_number: string };
    // console.log({checkout_url, payment_intent, reference_number});
    // return;

    if (checkout_url && payment_intent && reference_number) {
      // window.location.href = checkout_url;
      const hotelBooking = bookHotel({
        transactionId: transactionId,
        adults: searchCriteria.adults,
        rooms: selectedRooms.length,
        checkInDate: moment(searchCriteria.checkInDate).format('YYYY-MM-DD'),
        checkOutDate: moment(searchCriteria.checkOutDate).format('YYYY-MM-DD'),
        paymentHotelDetail: {
          name: guest.firstName + ' ' + guest.lastName,
          amount: selectedRooms[0].TotalFare,
          discount: 0,
          referenceNumber: reference_number,
          paymentIntentId: payment_intent.id,
        },
        customerDetail: {
          title: guest.title,
          firstName: guest.firstName,
          lastName: guest.lastName,
          email: guest.email,
          mobileNumber: `${guest.code.replace('+', '')}${guest.mobile}`,
        },
        provider: 'tbo',
        providerDetails: { ...hotel, ...{ Rooms: selectedRooms } },
      }); // Pass the required argument to the bookHotel function.

      const response = (await hotelBooking).data;
      console.log(response)

      const { statusCode } = response;

      if (statusCode !== HTTP_CREATED() || statusCode !== HTTP_OK()) {
        console.log(response);
        // jump to the index page
        // window.location.replace('/');
      }

      // if status code is 201 created proceed to paymongo page
      if (statusCode === HTTP_CREATED()) {
        // jump to the paymongo page
        window.location.replace(checkout_url);
      }
    } else {
      setDisableCancelPayButton(false);
      setDisablePayButton(false);
    }
  };

  return (
    <section className="text-text-colors-text-primary font-footnote absolute left-[calc(50%_-_205px)] top-[calc(50%_-_205px)] h-[25.63rem] w-[25.63rem] text-center text-[0.88rem]">
      <div className="absolute left-[calc(50%_-_166px)] top-[calc(50%_-_142px)] h-[17.19rem] w-[20.81rem]">
        <div className="absolute left-[calc(50%_-_166.5px)] top-[calc(50%_-_137.5px)] flex w-[20.81rem] flex-col items-center justify-center gap-[1.5rem]">
          <div className="flex flex-col items-center justify-center gap-[0.75rem] self-stretch">
            <div className="flex flex-col items-center justify-center gap-[1.26rem] self-stretch">
              {/* <img className="relative h-[4.36rem] w-[6.18rem] shrink-0 overflow-hidden object-cover" alt="" src="/svg/layer-1.svg" /> */}
              <GallyWinkSvg />
              <div className="font-footnote relative self-stretch font-medium">Redirecting you to our Payment Page</div>
            </div>
            <div className="text-text-colors-text-secondary font-footnote relative self-stretch text-[0.81rem] font-medium leading-[1.13rem]">
            Almost there! Double-check your booking details. Click Pay Now to proceed or Cancel to make any changes.
            </div>
          </div>
          <div className="text-color-shades-white-800 flex w-[60%] flex-col items-center justify-center gap-[0.63rem] text-left text-[0.81rem]">
            <GalagoButton onClick={onClickPayNow} disabled={disablePayButton} label="Pay Now" btn="primary" size="sm" className='shadow-md' />
            <GalagoButton onClick={onClose} disabled={disableCancelPayButton} label="Cancel" btn="light" size="sm" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HotelBookingModal;
